import { ReactElement } from 'react';
import './projectSection.css'
import TaskCard from '../../../components/taskCard/taskCard';
import React from 'react';

interface ProjectSectionProps {
    title?: string,
    indicatorColor?: string,
    taskCards?: Array<ReactElement<typeof TaskCard>>
}

function ProjectSection(props: ProjectSectionProps) {
    return (
        <div className="project-section">
            <div className='heading'>
                <span className='title'>{props.title}</span>
                <div className='indicator' style={{backgroundColor: props.indicatorColor}}></div>
            </div>

            <div className='task-cards'>
                {props.taskCards != null ? props.taskCards.map((TaskCard, index) => (
                    <React.Fragment key={index}>
                        {TaskCard}
                    </React.Fragment>
                )) : null}
            </div>
        </div>
    );
}

export default ProjectSection;