import './sidebar.css'

import ActionButton from "../actionButton/actionButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCalendar, faChartLine, faDashboard, faEllipsis, faFlag, faHeart, faHome, faMap, faMessage, faPaintBrush, faPlus, faSpider, faStopwatch, faWallet } from "@fortawesome/free-solid-svg-icons"
import MemberCard from '../memberCard/memberCard';

import profile2 from '../../assets/images/profile2.png'
import profile3 from '../../assets/images/profile3.png'

function Sidebar() {
    return (
        <div className="sidebar">
            <div className='left-section'>
                <div className="logo">
                    <FontAwesomeIcon style={{ fontSize: 30 }} icon={faSpider} color={'#5C69E2'} />
                </div>

                <div className="action-buttons">

                    <div className='top'>
                        <ActionButton icon={faHome} onPressed={console.log('Print Home')} />
                        <ActionButton icon={faMessage} onPressed={console.log('Print Home')} />
                        <ActionButton backgroundColor={'#5C69E2'} icon={faDashboard} onPressed={console.log('Print Home')} />
                        <ActionButton icon={faHeart} onPressed={console.log('Print Home')} />
                        <ActionButton icon={faChartLine} onPressed={console.log('Print Home')} />
                        <ActionButton icon={faMap} onPressed={console.log('Print')} />
                        <ActionButton icon={faStopwatch} onPressed={console.log('Print')} />
                    </div>

                    <div className='bottom'>
                        <ActionButton icon={faChartLine} onPressed={console.log('Print Home')} />
                        <ActionButton icon={faMap} onPressed={console.log('Print')} />
                        <div className='profile-icon' onClick={() => console.log('Profile Picture Pressed')}>
                            <img src={profile2} alt="profile" />
                        </div>
                    </div>
                </div>

            </div>

            <div className='right-section'>
                <h3>Projects</h3>

                <div className="action-buttons">
                    <ActionButton 
                        icon={faFlag} 
                        title='Campaigns'
                        trailing={
                        <div className='trailing' onClick={() => console.log('Print Trailing')}>          
                                <FontAwesomeIcon icon={faEllipsis} />
                        </div>
                        } 
                        onPressed={console.log('Print Regular')} 
                    />
                    <ActionButton 
                        icon={faFlag} 
                        title="Publications" 
                        trailing={
                            <div className='trailing' onClick={() => console.log('Print Trailing')}>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </div>
                        } 
                        onPressed={console.log('Print')} 
                    />
                    <ActionButton 
                        icon={faBookmark}
                        title="Topics" 
                        trailing={
                            <div className='trailing' onClick={() => console.log('Print Trailing')}>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </div>
                        } 
                        onPressed={console.log('Print')} 
                    />
                    <ActionButton 
                        icon={faCalendar} 
                        title='Planning' 
                        trailing={
                            <div className='trailing' onClick={() => console.log('Print Trailing')}>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </div>
                        } 
                        onPressed={console.log('Print')}
                     />
                    <ActionButton 
                        icon={faPaintBrush}
                        title='Design Internal' 
                        trailing={
                            <div className='trailing' onClick={() => console.log('Print Trailing')}>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </div>
                        } 
                        onPressed={console.log('Print')} 
                    />
                    <ActionButton 
                        icon={faWallet} 
                        title='Marketing'
                        trailing={
                            <div className='trailing' onClick={() => console.log('Print Trailing')}>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </div>
                        }  
                        onPressed={console.log('Print')} 
                    />
                </div>

                <div className='members-heading'>
                    <h3>Members</h3>
                    <div className='add-icon' onClick={() => console.log('Plus button')}><FontAwesomeIcon icon={faPlus}/></div>
                </div>
                

                <div className='member-cards'>
                    <MemberCard onPressed={() => console.log('Member Card 1')} />
                    <MemberCard photo={profile2} />
                    <MemberCard photo={profile3} />
                </div>
            </div>
        </div>
    );
}

export default Sidebar