import { faEllipsisVertical, faEye, faFilter, faPerson, faSearch, faSort } from '@fortawesome/free-solid-svg-icons'
import ActionButton from '../actionButton/actionButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './searchBar.css'

function SearchBar() {
    return (
        <div className="search-bar">
            <div className='search-input'>
                <FontAwesomeIcon icon={faSearch} />
                <input type='text' placeholder='Search tasks...' />
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>

            <div className='search-actions'>
                <ActionButton icon={faSort} title='Sort By' backgroundColor='transparent' />
                <ActionButton icon={faFilter} title='Filters' backgroundColor='transparent' />
                <ActionButton icon={faPerson} title='Me' backgroundColor='transparent' />
                <ActionButton icon={faEye} title='Show' backgroundColor='transparent' />
            </div>
        </div>
    )
}

export default SearchBar