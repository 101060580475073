import './memberCard.css'
import defaultPhoto from '../../assets/images/profile.png'

interface MemberCardProps {
    fullName?: string,
    totalTime?: string,
    photo?: string,
    onPressed?: VoidFunction,
}

MemberCard.defaultProps = {
    fullName: 'Cecil White',
    totalTime: '08:06:28 for this week',
    photo: defaultPhoto,
}

function MemberCard(props: MemberCardProps) {
    return (
        <div className='member-card' onClick={() => props.onPressed}>
            <img src={props.photo} alt="profile" />

            <div className='trailing'>
                <span className='title'>{ props.fullName }</span>
                <span className='subtitle'>{ props.totalTime }</span>
            </div>
        </div>
    );
}

export default MemberCard