import React from 'react';
import './styles/App.css';
import Sidebar from './components/sidebar/sidebar';
import Publications from './pages/publications/publications';

function App() {
  return (
    <div className='app-wrapper'>
      <Sidebar />
      <Publications />
    </div>
  );
}

export default App;
