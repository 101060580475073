import { ReactElement, useState } from 'react'
import Tag from '../tag/tag'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faComment, faEllipsisVertical, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import './taskCard.css'

interface TaskCardProps {
    tags?: Array<ReactElement<typeof Tag>>
    title?: string,
    subtitle?: string,
    date?: string,
    comments?: number,
    attachments?: number,
    members?: Array<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>
}

TaskCard.defaultProps = {
    title: 'Sample Task Card',
    subtitle: 'This is a sample subtitle. Hopefully you get the idea. This can be literally anything you want it to be.',
    date: '4 Aug',
    comments: 4,
    attachments: 3,
}

function TaskCard(props: TaskCardProps) {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragStart = () => {
        setIsDragging(true);
        const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
            // Create a dummy image element for the drag image
            const dragImage = new Image();
            dragImage.src = 'https://upload.wikimedia.org/wikipedia/commons/1/1e/Outdoors-man-portrait.jpg';
            dragImage.style.opacity = '0.5'; 

            // Set the custom drag image
            event.dataTransfer.setDragImage(dragImage, 0, 0);
        };
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <div 
            className={`task-card ${isDragging ? 'dragging' : ''}`} 
            draggable 
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}>

            <div className='header'>
                <div className='tags'>
                    {props.tags != null ? props.tags.map((Tag, index) => (
                        <React.Fragment key={index}>
                            {Tag}
                        </React.Fragment>
                    )) : null}
                </div>

                <FontAwesomeIcon className='icon' icon={faEllipsisVertical} />
            </div>

            <span className='title'>{props.title}</span>
            <p className='subtitle'>{props.subtitle}</p>

            <div className='date-button'>
                <FontAwesomeIcon icon={faCalendarAlt} />
                <span className='date'>{props.date}</span>
            </div>

            <div className='footer'>
                <div className='members'>
                    {props.members != null ? props.members.map((member, index) => (
                        <React.Fragment key={index}>
                            <img key={index} {...member} alt="member" />
                        </React.Fragment>
                    )): null}
                </div>
                <div className='stats'>
                    <div className='comments'>
                        <FontAwesomeIcon icon={faComment} />
                        <span className='count'>{props.comments}</span>
                    </div>
                    <div className='attachments'>
                        <FontAwesomeIcon icon={faPaperclip} />
                        <span className='count'>{props.attachments}</span>
                    </div>
                </div>
            </div>
        </div>   
    )
}

export default TaskCard