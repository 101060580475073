import './tag.css'

interface TagProps {
    title?: string,
    color?: string
    backgroundColor?: string
}

function Tag(props: TagProps) {
    return ( 
        <div className='tag' style={{color: props.color, backgroundColor: props.backgroundColor}}>
            <span className='title'>
                {props.title}
            </span>
        </div>
    );
}

export default Tag