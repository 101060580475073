import AppBar from '../../components/appBar/appBar';
import SearchBar from '../../components/searchBar/searchBar';
import Tag from '../../components/tag/tag';
import TaskCard from '../../components/taskCard/taskCard';
import ProjectSection from './projectSection/projectSection';
import './publications.css'

function Publications() {
    return(
        <div className='publications'>
            <AppBar title='Publications' />
            <SearchBar />

            <div className='project-board'>
                <ProjectSection 
                    title='To Do'
                    indicatorColor='#3959A9'
                    taskCards={
                        [
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Adobe XD' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />, 
                            <TaskCard title='Food Delivery App Case' tags={[<Tag title='Figma' backgroundColor='#265BF6' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Accounting Software Website' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#52A2A9' />]} />, 
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />
                        ]
                    } 
                />

                <ProjectSection
                    title='In Progress'
                    indicatorColor='#933733'
                    taskCards={
                        [
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />
                        ]
                    }
                />

                <ProjectSection
                    title='Under Review'
                    indicatorColor='#673CBE'
                    taskCards={
                        [
                            <TaskCard title='Administrator Panel Interface' subtitle="Develop an interface for the administrator to manage the rights of the company's users to various products(projects) of the company" tags={[<Tag title='Research' backgroundColor='#673CBE' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Online Catalog Development' tags={[<Tag title='Dev' backgroundColor='#66A0A7' />]} />,
                        ]
                    }
                />

                <ProjectSection
                    title='Ready'
                    indicatorColor='#83E78C'
                    taskCards={
                        [
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />,
                            <TaskCard title='Project Management Web App' tags={[<Tag title='Sample' backgroundColor='#CE5784' />, <Tag title='Design' backgroundColor='#E19357' />]} />
                        ]
                    }
                />
            </div>
        </div>
    );
}

export default Publications;