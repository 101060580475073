import { faCodeBranch, faList, faPlus, faShapes } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../actionButton/actionButton';
import './appBar.css'

interface AppBarProps {
    title?: string,
}

AppBar.defaultProps = {
    title: null,
}

function AppBar(props: AppBarProps) {
    return( 
        <div className="app-bar">
            <span className="title">{props.title}</span>

            <div className='action-buttons'>
                <ActionButton icon={faList} title='List' />
                <ActionButton icon={faShapes} title='Board' />
                <ActionButton icon={faCodeBranch} title='Workflow' />
                <span className='separator'></span>
                <ActionButton icon={faPlus} title='View' />
            </div>
        </div>
    );
}

export default AppBar