import './actionButton.css'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ActionButtonProps {
    title?: string,
    color?: string,
    size?: number,
    icon?: IconDefinition,
    trailing?: JSX.Element, 
    iconColor?: string,
    backgroundColor: string,
    onPressed?: void,
}

ActionButton.defaultProps = {
    title: null,
    size: null,
    color: '#FFFFFF',
    backgroundColor: '#282A2D'
};

function ActionButton(props: ActionButtonProps) {
    return ( 
        <div className='action-button' onClick={() => props.onPressed}  style={{ color: props.color, backgroundColor: props.backgroundColor, width: props.size, height: props.size }}>
                <div className='leading'>
                    { props.icon != null ? <FontAwesomeIcon icon={props.icon} color={props.iconColor ?? '#FFFFFF'} /> : null }
                    { props.title != null ? <span>{props.title}</span> : null }
                </div>

                <div className='trailing'> 
                    { props.trailing}
                </div>
        </div>
    );
}

export default ActionButton